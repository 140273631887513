import { TeamOutlined } from '@ant-design/icons';
import { Layout, Menu, Typography } from 'antd';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

interface SidebarProps {
	collapsed: boolean;
}

const LayoutSidebar: React.FC<SidebarProps> = ({ collapsed }) => {
	const { formatMessage } = useIntl();
	const history = useHistory();
	return (
		<Layout.Sider trigger={null} collapsible collapsed={collapsed}>
			<div className="logo flex justify-center items-center py-5">
				<img width="50%" height="50%" src="/img/CESM.jpg" alt="CESM" />
			</div>
			{!collapsed ? (
				<div className="text-center">
					<Typography.Title className="text-white" level={4}>
						Confederación Española de Sociedades Musicales
					</Typography.Title>
				</div>
			) : null}

			<Menu
				className="pt-5"
				theme="dark"
				mode="inline"
				onClick={({ key }) => history.push(`/${key}`)}
				defaultSelectedKeys={['federations']}
				items={[
					{
						key: 'federations',
						icon: <TeamOutlined />,
						label: formatMessage({ id: 'auth.signin' }),
					},
				]}
			/>
		</Layout.Sider>
	);
};

export default LayoutSidebar;
