import { Image, Typography } from 'antd';
import { USER_PHOTO_DEFAULT } from 'helpers/constants';
import { useAuth } from 'router/AuthContext';
import LogoutButton from './Logout';

const HeaderUserMenu: React.FC = () => {
	const user = useAuth();
	return (
		<div className="flex items-center">
			<Image
				src={user.photoURL}
				alt={user.displayName}
				width={48}
				height={48}
				preview={false}
				className="rounded-full"
				fallback={USER_PHOTO_DEFAULT}
			/>
			<div className="leading-5 mt-1 ml-4">
				<Typography.Text>{user.displayName}</Typography.Text>
				<br />
				<LogoutButton />
			</div>
		</div>
	);
};
export default HeaderUserMenu;
