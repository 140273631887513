import update from 'immutability-helper';
import { FetchListState, initalListState } from 'modules/firestore';
import { Reducer } from 'redux';
import { FederationAction, FederationDocData, FederationTypes } from './types';

export interface FederationState {
	federationList: FetchListState<FederationDocData>;
}

export const initialState: FederationState = {
	federationList: initalListState,
};

const reducer: Reducer<FederationState, FederationAction> = (
	state = initialState,
	action
) => {
	switch (action.type) {
		case FederationTypes.SET_FEDERATIONS_LIST: {
			return update(state, {
				federationList: {
					$merge: action.payload,
				},
			});
		}
		default:
			return state;
	}
};

export default reducer;
