import { createBrowserHistory } from 'history';
import createRootReducer, { ApplicationState } from 'modules/root';
import { Store } from 'redux';
import { configureStore as configStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

export const history = createBrowserHistory();

export const configureStore = (): Store<ApplicationState> => {
	const middlewares = [thunk];

	return configStore({
		reducer: createRootReducer(),
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware().concat(middlewares),
	});
};
