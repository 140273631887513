import { Grid, Layout } from 'antd';
import Sider from './Sidebar';
import React, { useEffect, useState } from 'react';
import Header from './Header';

interface Props {
	children: React.ReactNode;
}

const DashboardLayout: React.FC<Props> = ({ children }) => {
	const { xl } = Grid.useBreakpoint();
	const [collapsed, setCollapsed] = useState(false);

	useEffect(() => {
		setCollapsed(!xl);
	}, [xl]);

	return (
		<Layout>
			<Sider collapsed={collapsed} />
			<Layout>
				<Header collapsed={collapsed} setCollapsed={setCollapsed} />
				<Layout.Content className="min-h-screen bg-gray-200">
					{children}
				</Layout.Content>
			</Layout>
		</Layout>
	);
};

export default DashboardLayout;
