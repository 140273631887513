import { FetchListState } from 'modules/firestore';
import { Action } from 'redux';
import { FederationPartnershipData } from '@glissandoo/lib/models/Federation/Partnership/types';

export enum PartnershipTypes {
	SET_PARTNERSHIP_LIST = 'SET_PARTNERSHIP_LIST',
}

export enum PartnershipParams {
	Id = 'id',
	Displayname = 'displayName',
	PhotoURL = 'photoURL',
	Address = 'address',
	Email = 'email',
	CIF = 'CIF',
	Phone = 'phone',
	MemberCount = 'memberCount',
	PartnerCount = 'partnerCount',
	LeaderCount = 'leaderCount',
	HasSchool = 'hasSchool',
	TeachersCount = 'teacherCount',
	StudentCount = 'studentCount',
	UpdatedAt = 'updatedAt',
}
export type PartnershipDocData = Pick<
	FederationPartnershipData & {
		id: string;
	},
	PartnershipParams
>;

interface UpdateListPartnershipAction extends Action {
	type: PartnershipTypes.SET_PARTNERSHIP_LIST;
	payload: Partial<FetchListState<PartnershipDocData>>;
}

export type PartnershipAction = UpdateListPartnershipAction;
