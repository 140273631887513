import Layout from 'antd/lib/layout';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import React from 'react';
import UserMenu from './UserMenu';

interface HeaderProps {
	collapsed: boolean;
	setCollapsed: (collapsed: boolean) => void;
}

const LayoutHeader: React.FC<HeaderProps> = ({ collapsed, setCollapsed }) => {
	return (
		<Layout.Header className="px-12 flex justify-between items-center bg-white p-0 h-20">
			{React.createElement(
				collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
				{
					className: 'trigger text-lg',
					onClick: () => setCollapsed(!collapsed),
				}
			)}
			<UserMenu />
		</Layout.Header>
	);
};
export default LayoutHeader;
