import update from 'immutability-helper';
import { FetchListState, initalListState } from 'modules/firestore';
import { Reducer } from 'redux';
import {
	PartnershipAction,
	PartnershipDocData,
	PartnershipTypes,
} from './types';

export interface PartnershipState {
	partnershipList: FetchListState<PartnershipDocData>;
}

export const initialState: PartnershipState = {
	partnershipList: initalListState,
};

const reducer: Reducer<PartnershipState, PartnershipAction> = (
	state = initialState,
	action
) => {
	switch (action.type) {
		case PartnershipTypes.SET_PARTNERSHIP_LIST: {
			return update(state, {
				partnershipList: {
					$merge: action.payload,
				},
			});
		}
		default:
			return state;
	}
};

export default reducer;
