import { FetchListState } from 'modules/firestore';
import { Action } from 'redux';
import { FederationData } from '@glissandoo/lib/models/Federation/types';

export enum FederationTypes {
	SET_FEDERATIONS_LIST = 'SET_FEDERATIONS_LIST',
}

export enum FederationParams {
	Id = 'id',
	Displayname = 'displayName',
	PhotoURL = 'photoURL',
	Email = 'email',
	Web = 'web',
	Phone = 'phone',
	Address = 'address',
	partnershipIds = 'partnershipIds',
}
export type FederationDocData = Pick<
	FederationData & {
		id: string;
	},
	FederationParams
>;

interface UpdateListFederationAction extends Action {
	type: FederationTypes.SET_FEDERATIONS_LIST;
	payload: Partial<FetchListState<FederationDocData>>;
}

export type FederationAction = UpdateListFederationAction;
