import { Spin } from 'antd';
import { FC, lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
const FederationsList = lazy(() => import('routes/Federations/List'));
const FederationView = lazy(() => import('routes/Federations/View'));

const DashboardRoute: FC = () => {
	return (
		<Suspense fallback={<Spin />}>
			<Switch>
				<Route exact path="/federations" component={FederationsList} />
				<Route
					exact
					path="/federations/:id"
					component={FederationView}
				/>
				<Route path="*" render={() => <Redirect to="/federations" />} />
			</Switch>
		</Suspense>
	);
};

export default DashboardRoute;
