import { AuthErrors, getAuthURL } from '@glissandoo/lib/helpers/auth';
import { LanguagesTypes } from '@glissandoo/lib/lang';
import { AuthUserDocData } from 'modules/auth/types';
import { ApplicationState } from 'modules/root';
import { createContext, FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

export const AuthContext = createContext<AuthUserDocData | null>(null);

interface Props {
	children: React.ReactNode;
}

export const AuthProvider: FC<Props> = ({ children }) => {
	const intl = useIntl();
	const { user } = useSelector((state: ApplicationState) => state.auth);

	if (user.loading) {
		return null;
	}

	if (user.error || !user.data) {
		window.location.href = getAuthURL(
			'signin',
			intl.locale as LanguagesTypes,
			{
				return_to: window.location.href,
				error: AuthErrors.Unauthenticated,
			}
		);
		return null;
	}

	return (
		<AuthContext.Provider value={user.data}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (context === null) {
		throw new Error(`useAuth must be used within a AuthProvider`);
	}
	return context;
};
