import { LanguagesTypes } from '@glissandoo/lib/lang';
import { ConfigProvider } from 'antd';
import { AppTypes } from 'modules/app/types';

import { getUser } from 'modules/auth/selectors';
import { ApplicationState } from 'modules/root';
import { FC, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { getLocale } from './config';

interface Props {
	children: React.ReactNode;
}

const AppLanguage: FC<Props> = ({ children }) => {
	const dispatch = useDispatch();
	const [langParam, setLangParam] = useState<LanguagesTypes>();

	const user = useSelector((state: ApplicationState) => getUser(state));

	const currentLang = getLocale(user ? user.language : langParam);

	/**
	 * Cacheamos el parámetro lang si este existe
	 */
	useMount(() => {
		const lang: LanguagesTypes | null = new URL(
			window.location.href
		).searchParams.get('lang') as LanguagesTypes;
		setLangParam(lang || undefined);
	});

	/**
	 * Si hemos recibido un parámetro lang actualizamos el campo language del usuario
	 */
	useEffect(() => {
		if (langParam && user) {
			//TODO
			// dispatch(authActions.updateLanguage(langParam));
			setLangParam(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		dispatch({
			type: AppTypes.SET_LANG,
			payload: currentLang.locale,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentLang]);

	return (
		<ConfigProvider locale={currentLang.antd}>
			<IntlProvider
				locale={currentLang.locale}
				messages={currentLang.messages}
			>
				{children}
			</IntlProvider>
		</ConfigProvider>
	);
};

export default AppLanguage;
