import {
	AuthErrors,
	AuthUserClaims,
	getAuthURL,
} from '@glissandoo/lib/helpers/auth';
import { CollectionNames } from '@glissandoo/lib/helpers/collections';
import { LanguagesTypes } from '@glissandoo/lib/lang';
import firebase from 'firebase/app';
import { auth } from 'functions/auth';
import { userDB } from 'functions/user';
import pick from 'lodash/pick';

import { getFirestoreViewHandler, ModuleViewActions } from 'modules/firestore';
import { ApplicationState } from 'modules/root';
import { ThunkAction } from 'redux-thunk';
import { AppActions, AppTypes } from '../app/types';
import { getUser } from './selectors';
import { AuthActions, AuthTypes, AuthUserDocData, UserParams } from './types';

type ThunkResult<R> = ThunkAction<R, ApplicationState, undefined, AuthActions>;
type ThunkResultAll<R> = ThunkAction<
	R,
	ApplicationState,
	undefined,
	AuthActions | AppActions
>;

const formatUserView = (
	doc: firebase.firestore.DocumentSnapshot
): AuthUserDocData => {
	const model = userDB.model(doc);
	const params = Object.values(UserParams);
	return pick(model, params);
};

const fetchUser =
	(action: ModuleViewActions, userId: string): ThunkResult<Promise<void>> =>
	async (dispatch, getState) => {
		const state = getState();

		const endpoint = firebase
			.firestore()
			.collection(CollectionNames.User)
			.doc(userId);

		await dispatch(
			getFirestoreViewHandler<AuthUserDocData>({
				action,
				view: state.auth.user,
				type: AuthTypes.SET_USER,
				endpoint,
				formatView: formatUserView,
			})
		);

		return Promise.resolve();
	};

const signInByEmailPassword = async (email: string, password: string) => {
	const { user } = await firebase
		.auth()
		.signInWithEmailAndPassword(email, password);

	if (!user) {
		throw new Error(AuthErrors.Unauthenticated);
	}
	return user;
};

const signInByToken = async () => {
	const idToken = await auth.status();
	if (!idToken) {
		throw new Error(AuthErrors.Unauthenticated);
	}

	const { user } = await firebase.auth().signInWithCustomToken(idToken);

	if (!user) {
		throw new Error(AuthErrors.Unauthenticated);
	}

	return user;
};

const firebaseAuthInit = () => {
	if (process.env.NODE_ENV !== 'production') {
		return signInByEmailPassword(
			process.env.REACT_APP_USER_EMAIL || '',
			process.env.REACT_APP_USER_PASSWORD || ''
		);
	}

	return signInByToken();
};

const getClaims = async (user: firebase.User) => {
	const result = await user.getIdTokenResult();
	return result.claims as AuthUserClaims;
};

const checkPartnershipPermission = async (user: firebase.User) => {
	const claims = await getClaims(user);

	if (!claims.besm) {
		throw new Error(AuthErrors.NoFederationAdmin);
	}
};

const clearAll = (): ThunkResultAll<void> => (dispatch) => {
	dispatch({ type: AuthTypes.CLEAR_ME });
	dispatch({ type: AppTypes.CLEAR_APP });
};

const init =
	(): ThunkResult<Promise<firebase.User>> => async (dispatch, getState) => {
		try {
			dispatch(clearAll());

			const user = await firebaseAuthInit();
			await checkPartnershipPermission(user);

			await dispatch(fetchUser(ModuleViewActions.INIT, user.uid));
			return user;
		} catch (error) {
			const state = getState();
			window.location.href = getAuthURL('signin', state.app.lang, {
				return_to: window.location.href,
				...(error instanceof Error && { error: error.message }),
			});
			return Promise.reject(error);
		}
	};

const updateLanguage =
	(language: LanguagesTypes): ThunkResultAll<Promise<void>> =>
	async (dispatch, getState) => {
		const state = getState();
		const prevUser = getUser(state);

		if (!prevUser || prevUser.language === language) {
			return Promise.resolve();
		}

		dispatch({
			type: AuthTypes.UPDATE_USER,
			payload: { language },
		});

		dispatch({
			type: AppTypes.SET_LANG,
			payload: language,
		});

		return userDB.update(prevUser.id, { language }).catch(() => {
			dispatch({
				type: AuthTypes.UPDATE_USER,
				payload: prevUser,
			});

			dispatch({
				type: AppTypes.SET_LANG,
				payload: prevUser.language,
			});
		});
	};

export const authActions = {
	init,
	updateLanguage,
};
