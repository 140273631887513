interface Props {
	children: React.ReactNode;
}

const LayoutCentered: React.FC<Props> = ({ children }) => {
	return (
		<div className="w-full h-screen flex justify-center items-center bg-gray-100">
			<div className="relative flex flex-col justify-center items-center">
				{children}
			</div>
		</div>
	);
};

export default LayoutCentered;
