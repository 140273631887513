import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ApplicationState } from './root';
import firebase from 'firebase/app';

export interface FetchListState<D> {
	items: D[];
	ready: boolean;
	isLoading: boolean;
	error: boolean;
	loadMore: boolean;
	isLoadingMore: boolean;
}

export interface FetchViewState<D> {
	data: D | null;
	ready: boolean;
	loading: boolean;
	error: boolean;
}

type ThunkResult<R> = ThunkAction<
	R,
	ApplicationState,
	undefined,
	Action<string>
>;

type FormatItemFn<D> = (
	doc: firebase.firestore.DocumentSnapshot,
	state: ApplicationState
) => D;

interface FirestoreViewHandlerParams<D> extends FirestoreViewParams<D> {
	action: ModuleViewActions;
}

interface FirestoreViewParams<D> {
	view: FetchViewState<D> | null;
	type: string;
	endpoint: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
	formatView: FormatItemFn<D>;
}

export enum ModuleListActions {
	INIT = 'init',
	LOADMORE = 'loadmore',
	REFRESH = 'refresh',
	NEWS = 'news',
}

export enum ModuleViewActions {
	INIT = 'init',
	REFRESH = 'refresh',
}

export const initialViewState = {
	loading: false,
	view: null,
	data: null,
	error: false,
	ready: false,
};

export const initalListState = {
	items: [],
	isLoading: false,
	error: false,
	loadMore: true,
	isLoadingMore: false,
	ready: false,
};

export function getFirestoreViewHandler<D>({
	action,
	...rest
}: FirestoreViewHandlerParams<D>): ThunkResult<Promise<void>> {
	return async (dispatch) => {
		switch (action) {
			case ModuleViewActions.INIT: {
				return dispatch(getFirestoreViewInit(rest));
			}
			case ModuleViewActions.REFRESH: {
				return dispatch(getFirestoreViewRefresh(rest));
			}
			default: {
				return;
			}
		}
	};
}

export function getFirestoreViewRefresh<D>(
	params: FirestoreViewParams<D>
): ThunkResult<Promise<void>> {
	return async (dispatch, getState) => {
		const { view, type, endpoint, formatView } = params;
		const state = getState();
		const { id } = endpoint;

		if (!view || view.loading) {
			return Promise.resolve();
		}

		try {
			const doc = await endpoint.get();

			if (!doc.exists) {
				dispatch({
					type,
					payload: {
						id,
						value: { loading: false, error: true },
					},
				});
				return Promise.resolve();
			}

			const data = formatView(doc, state);

			dispatch({
				type,
				payload: {
					id,
					value: { data, loading: false, error: false },
				},
			});
			return Promise.resolve();
		} catch (error) {
			dispatch({
				type,
				payload: {
					id,
					value: {
						data: null,
						loading: false,
						error: true,
					},
				},
			});
			return Promise.reject(error);
		}
	};
}

export function getFirestoreViewInit<D>(
	params: FirestoreViewParams<D>
): ThunkResult<Promise<void>> {
	return async (dispatch, getState) => {
		const { view, type, endpoint, formatView } = params;
		const state = getState();
		const { id } = endpoint;

		if (view && view.loading) {
			return Promise.resolve();
		}

		if (!view) {
			dispatch({
				type,
				payload: {
					id,
					value: {
						data: null,
						loading: true,
						error: false,
						ready: false,
					},
				},
			});
		}

		try {
			const doc = await endpoint.get();

			if (!doc.exists) {
				dispatch({
					type,
					payload: {
						id,
						value: { ready: true, loading: false, error: true },
					},
				});
				return Promise.resolve();
			}

			const data = formatView(doc, state);

			dispatch({
				type,
				payload: {
					id,
					value: { data, loading: false, error: false, ready: true },
				},
			});
			return Promise.resolve();
		} catch (error) {
			dispatch({
				type,
				payload: {
					id,
					value: {
						data: null,
						loading: false,
						error: true,
						ready: true,
					},
				},
			});
			return Promise.reject(error);
		}
	};
}
