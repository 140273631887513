import { useDispatch } from 'react-redux';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import app from './app/reducer';
import { AppState } from './app/types';
import auth from './auth/reducer';
import { AuthState } from './auth/types';
import federations, { FederationState } from './federations/reducer';
import partnerships, { PartnershipState } from './partnerships/reducer';

export interface ApplicationState {
	app: AppState;
	auth: AuthState;
	federations: FederationState;
	partnerships: PartnershipState;
}

export const useAppDispatch = (): ThunkDispatch<
	ApplicationState,
	undefined,
	AnyAction
> => useDispatch();

const createRootReducer = () =>
	combineReducers({
		app,
		auth,
		federations,
		partnerships,
	});

export default createRootReducer;
