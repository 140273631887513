import { ApplicationState, useAppDispatch } from 'modules/root';
import Routes from './dashboard';
import LayoutDashboard from 'layouts/Dashboard';
import { useSelector } from 'react-redux';
import { isLoaded } from 'modules/auth/selectors';
import { AuthProvider } from './AuthContext';
import LoadingScreen from 'routes/Loading';
import { useMount } from 'react-use';
import { authActions } from 'modules/auth/actions';

const DashboardRoutes: React.FC = () => {
	const dispatch = useAppDispatch();
	const isLogged = useSelector((state: ApplicationState) => isLoaded(state));

	useMount(() => {
		dispatch(authActions.init());
	});

	if (!isLogged) {
		return <LoadingScreen />;
	}
	return (
		<AuthProvider>
			<LayoutDashboard>
				<Routes />
			</LayoutDashboard>
		</AuthProvider>
	);
};

export default DashboardRoutes;
